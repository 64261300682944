// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCraBFI4Q0U6xZo4gMfBzY6kjffwnpg78k",
  authDomain: "advex-client-server.firebaseapp.com",
  projectId: "advex-client-server",
  storageBucket: "advex-client-server.appspot.com",
  messagingSenderId: "372576727208",
  appId: "1:372576727208:web:1bac8a96507876a672929d",
  measurementId: "G-FBXBE5XCM4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
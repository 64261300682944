import React, { useState, useEffect, useRef } from 'react';
import "../output.css";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from 'react-router-dom';
import { backend_url } from "../constants";

import { auth } from '../firebase/firebase';

export const GetJobsQueue = () => {
    const [jobsQueue, setJobsQueue] = useState(null);
    const url = backend_url + '/display_jobs';

    useEffect(() => {
        // This function fetches the jobs queue from the server
        const fetchJobsQueue = async () => {
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'ngrok-skip-browser-warning': 'true',
                    },
                });

                const data = await response.json();
                setJobsQueue(JSON.stringify(data, null, 2)); // Beautify JSON string for display
            } catch (error) {
                console.error('Failed to fetch jobs queue:', error);
                setJobsQueue('Failed to load data');
            }
        };

        fetchJobsQueue();
    }, []); // Empty dependency array ensures this effect runs only once after initial render

    return (
        <div>
            <h1>Jobs Queue</h1>
            <pre id="jobs-queue">{jobsQueue || 'Loading...'}</pre>
        </div>
    );
};

export const RemoveJob = () => {
    const [jobRemoved, setJobRemoved] = useState(null);
    
    useEffect(() => {
        const urlParts = window.location.pathname.split('/');
        const lastPart = urlParts[urlParts.length - 1];
        const url = `${backend_url}/remove_job?id=${lastPart}`;

        const removeJob = async () => {
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'ngrok-skip-browser-warning': 'true',
                    },
                });
                const data = await response.json();
                setJobRemoved(JSON.stringify(data, null, 2));  // Beautify JSON string for display
            } catch (error) {
                console.error('Failed to remove job:', error);
                setJobRemoved('Failed to load data');
            }
        };

        removeJob();
    }, []); // Empty dependency array to ensure it only runs once on mount

    return (
        <div>
            <h1>Remove Job</h1>
            <pre id="remove-job">{jobRemoved || 'Processing...'}</pre>
        </div>
    );
};


export const Logout = ({ setUser }) => {
    const navigate = useNavigate();
  
    useEffect(() => {
      const signOutUser = async () => {
        try {
          await auth.signOut();
          setUser(null); // Reset user state to null
          navigate('/login');
        } catch (error) {
          console.error('Error signing out: ', error);
        }
      };
  
      signOutUser();
    }, [navigate, setUser]);
  
    return null;
  };
  
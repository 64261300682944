import { auth } from '../firebase/firebase';
import { GoogleAuthProvider, signInWithRedirect, signInWithPopup } from 'firebase/auth';
import GoogleButton from 'react-google-button'

export const AuthButton = () => {
  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    // signInWithRedirect(auth, provider);
    signInWithPopup(auth, provider);
  };

  return (
    <GoogleButton onClick={signInWithGoogle} />
  );
}
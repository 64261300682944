import "../output.css";
import "react-multi-carousel/lib/styles.css";

import React from 'react';
import { AuthButton } from '../components/AuthButton';

const Footer = () => {
    return (
        <footer className="bg-gray-800 py-4 mt-auto">
            <div className="container mx-auto text-center text-white">
                <p>
                    Contact Support: {' '}
                    <a href="mailto:support@advexai.com?subject=Advex AI Bug" className="underline">support@advexai.com</a>
                </p>
            </div>
        </footer>
    );
};

export const LoginPage = () => {
    return (
        <>
        <div className="min-h-screen flex flex-col">
            <div className="flex-grow">
                {/* Title */}
                <div className="App">
                    <div className="mb-8 mt-8">
                        <h1 className="text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl">Advex Data Engine</h1>
                        <h2 className="text-2xl mt-4 text-gray-600">
                            Solve data. Solve AI.
                        </h2>
                    </div> 
                </div>
        
                {/* Call to action */}
                <div>
                    <p className="text-xl text-center mt-10 font-bold">Try Advex on your own dataset</p>
                </div>

                {/* Sign in functinality */}

                <div className="flex items-center justify-center w-full mt-5 ">
                    <AuthButton />
                </div>

                {/* Examples */}
                <div>
                    <div class="relative flex mt-10 items-center">
                        <div class="flex-grow"></div>
                        <div class="flex-shrink w-2/5">
                            <div class="border-t border-gray-400"></div>
                        </div>
                        <span class="mx-4 text-black-400 text-2xl">Try out examples</span>
                        <div class="flex-shrink w-2/5">
                            <div class="border-t border-gray-400"></div>
                        </div>
                        <div class="flex-grow"></div>
                    </div>

                    <div class="flex mt-10 justify-center">
                        <a href="/metrics/81cdc9aed13b4b91907d99368dce7b6f" class="block w-full max-w-lg mx-auto">
                            <figure class="max-w-full">
                                <img class="h-auto max-w-full rounded-lg" src={process.env.PUBLIC_URL + '/PackageLoopFinal.gif'} alt="package segmentation model report"/>
                                <figcaption class="mt-2 text-big font-extrabold text-center text-black-500 dark:text-black-400">Package Segmentation</figcaption>
                            </figure>
                        </a>
                        <a href="/metrics/1b6dfe44f5184c828b42a44824562b55" class="block w-full max-w-lg mx-auto">
                            <figure class="max-w-full mx-auto">
                                <img class="h-auto max-w-full rounded-lg" src={process.env.PUBLIC_URL + '/RoadLoop.gif'} alt="plant segmentation model report"></img>
                                <figcaption class="mt-2 text-big text-center font-extrabold text-black-500 dark:text-black-400">Road Segmentation</figcaption>
                            </figure>
                        </a>
                        <a href="/metrics/4819869023bd43fe9781860384a97220" class="block w-full max-w-lg mx-auto">
                            <figure class="max-w-full mx-auto">
                                <img class="h-auto max-w-full rounded-lg" src={process.env.PUBLIC_URL + '/BoxesLoop.gif'} alt="box unloading model report"></img>
                                <figcaption class="mt-2 text-big text-center font-extrabold text-black-500 dark:text-black-400">Box Unloading</figcaption>
                            </figure>
                        </a>

                        {/* Remove for now */}
                        {/* <a href="/metrics/4819869023bd43fe9781860384a97221" class="block w-full max-w-lg mx-auto">
                            <figure class="max-w-full mx-auto">
                                <img class="h-auto max-w-full rounded-lg" src={process.env.PUBLIC_URL + '/BoxesLoop.gif'} alt="defect detection model report"></img>
                                <figcaption class="mt-2 text-big text-center font-extrabold text-black-500 dark:text-black-400">Defect Detection</figcaption>
                            </figure>
                        </a> */}
                    </div>
                </div>

                {/* Footer */}
                <br></br>
                <br></br>
                <br></br>
                <Footer />
            </div>
        </div>
        </>
    );
};
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import "../output.css";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from 'react-router-dom';
import { backend_url, approved_organizations } from '../constants';
import { useUser } from '../contexts/UserContext';


const useFolderStructure = () => {
    const location = useLocation();
    const currentPath = location.pathname;

    if (currentPath.endsWith('general')) {
        return {
            type: 'general',
            description: 'Folder structure: folder_name/images and folder_name/masks'
        };
    } else if (currentPath.endsWith('translation')) {
        return {
            type: 'translation',
            description: 'Folder structure: folder_name/defective/images, folder_name/defective/masks and folder_name/clean/images'
        };
    } else {
        return {
            type: 'error',
            description: 'Folder structure: Unknown'
        };
    }
};


const Footer = () => {
    return (
        <footer className="bg-gray-800 py-4 mt-auto">
            <div className="container mx-auto text-center text-white">
                <p>
                    Contact Support: {' '}
                    <a href="mailto:support@advexai.com?subject=Advex AI Bug" className="underline">support@advexai.com</a>
                </p>
            </div>
        </footer>
    );
};

// GENERAL ROUTE VALIDATIONS - Keep exactly as they were
const TestFoldersNotPresent = (files) => {
    let imagesFolder = false;
    let masksFolder = false;
    for (let file of files) {
        if (file.webkitRelativePath.includes('/images/')) {
            imagesFolder = true;
        }
        if (file.webkitRelativePath.includes('/masks/')) {
            masksFolder = true;
        }
    }

    if (!imagesFolder || !masksFolder) {
        return true;
    }
    return false;
}

const TestFewerThanTenImages = (files) => {
    let images = [];
    for (let file of files) {
        if (file.webkitRelativePath.includes('/images/')) {
            images.push(file);
        }
    }
    if (images.length < 10) {
        return true;
    }
    
    let masks = [];
    for (let file of files) {
        if (file.webkitRelativePath.includes('/masks/')) {
            masks.push(file);
        }
    }
    
    if (masks.length < 10){
        return true;
    }

    return false;
}

const TestFoldersEmpty = (files) => {
    let images = [];
    let masks = [];
    for (let file of files) {
        if (file.webkitRelativePath.includes('/images/')) {
            images.push(file);
        }
        
        if (file.webkitRelativePath.includes('/masks/')) {
            masks.push(file);
        }
    }
    if (images.length === 0 || masks.length === 0) {
        return true;
    }
    return false;
}

const TestDifferentNumberOfImages = (files) => {
    let images = [];
    let masks = [];
    for (let file of files) {
        if (file.webkitRelativePath.includes('/images/')) {
            images.push(file);
        }
        if (file.webkitRelativePath.includes('/masks/')) {
            masks.push(file);
        }
    }
    if (images.length !== masks.length) {
        return true;
    }
    return false;
}

const TestDifferentNamesExtensions = (files) => {
    let images = [];
    let masks = [];
    
    for (let file of files) {
        if (file.webkitRelativePath.includes('/images/')) {
            images.push(file);
        }
        if (file.webkitRelativePath.includes('/masks/')) {
            masks.push(file);
        }
    }
    for (let i = 0; i < images.length; i++) {
        const image = images[i];
        const mask = masks[i];
        if (image.name.split('.')[0] !== mask.name.split('.')[0]) {
            return true;
        }
    }
    return false;
}

// TRANSLATION ROUTE VALIDATIONS - Completely separate implementations
const TestTranslationFoldersStructure = (files) => {
    let defectiveImagesFolder = false;
    let defectiveMasksFolder = false;
    let cleanImagesFolder = false;

    for (let file of files) {
        const path = file.webkitRelativePath;
        if (path.includes('/defective/images/')) {
            defectiveImagesFolder = true;
        }
        if (path.includes('/defective/masks/')) {
            defectiveMasksFolder = true;
        }
        if (path.includes('/clean/images/')) {
            cleanImagesFolder = true;
        }
    }

    return !(defectiveImagesFolder && defectiveMasksFolder && cleanImagesFolder);
}

const TestTranslationFoldersEmpty = (files) => {
    let defectiveImages = [];
    let defectiveMasks = [];
    let cleanImages = [];

    for (let file of files) {
        const path = file.webkitRelativePath;
        if (path.includes('/defective/images/')) {
            defectiveImages.push(file);
        }
        if (path.includes('/defective/masks/')) {
            defectiveMasks.push(file);
        }
        if (path.includes('/clean/images/')) {
            cleanImages.push(file);
        }
    }

    return defectiveImages.length === 0 || defectiveMasks.length === 0 || cleanImages.length === 0;
}

const TestTranslationDefectiveMinimumImages = (files) => {
    let defectiveImages = [];
    let defectiveMasks = [];

    for (let file of files) {
        const path = file.webkitRelativePath;
        if (path.includes('/defective/images/')) {
            defectiveImages.push(file);
        }
        if (path.includes('/defective/masks/')) {
            defectiveMasks.push(file);
        }
    }

    return defectiveImages.length < 10 || defectiveMasks.length < 10;
}

const TestTranslationDefectiveMatchingCount = (files) => {
    let defectiveImages = [];
    let defectiveMasks = [];

    for (let file of files) {
        const path = file.webkitRelativePath;
        if (path.includes('/defective/images/')) {
            defectiveImages.push(file);
        }
        if (path.includes('/defective/masks/')) {
            defectiveMasks.push(file);
        }
    }

    return defectiveImages.length !== defectiveMasks.length;
}

const TestTranslationDefectiveMatchingNames = (files) => {
    let defectiveImages = [];
    let defectiveMasks = [];

    for (let file of files) {
        const path = file.webkitRelativePath;
        if (path.includes('/defective/images/')) {
            defectiveImages.push(file);
        }
        if (path.includes('/defective/masks/')) {
            defectiveMasks.push(file);
        }
    }

    // Sort both arrays to ensure we're comparing corresponding files
    defectiveImages.sort((a, b) => a.name.localeCompare(b.name));
    defectiveMasks.sort((a, b) => a.name.localeCompare(b.name));

    for (let i = 0; i < defectiveImages.length; i++) {
        const image = defectiveImages[i];
        const mask = defectiveMasks[i];
        // Check both the name and extension match exactly
        if (image.name !== mask.name) {
            return true;
        }
    }
    return false;
}

const TestFileLimitExceeded = (files) => {
    if (files.length > 400) {
        return true;
    }
    return false;       
}


/* // Modularized case handling for fetch request

// Case I - images/ and masks/ folders not present
const TestFoldersNotPresent = (files) => {
    let imagesFolder = false;
    let masksFolder = false;
    for (let file of files) {
        if (file.webkitRelativePath.includes('/images/')) {
            imagesFolder = true;
        }
        if (file.webkitRelativePath.includes('/masks/')) {
            masksFolder = true;
        }
    }

    if (!imagesFolder || !masksFolder) {
        return true;
    }
    return false;
}
// Case II - number of images less than 10
const TestFewerThanTenImages = (files) => {
    // Go into the images folder and count the number of images
    let images = [];
    for (let file of files) {
        if (file.webkitRelativePath.includes('/images/')) {
            images.push(file);
        }
    }
    if (images.length < 10) {
        return true;
    }
    
    // Go into the masks folder and count the number of images
    let masks = [];
    for (let file of files) {
        if (file.webkitRelativePath.includes('/masks/')) {
            masks.push(file);
        }
    }
    
    if (masks.length < 10){
        return true;
    }

    return false;
}

// Case III - images/ and masks/ folders are empty
const TestFoldersEmpty = (files) => {
    let images = [];
    let masks = [];
    for (let file of files) {
        if (file.webkitRelativePath.includes('/images/')) {
            images.push(file);
        }
        
        if (file.webkitRelativePath.includes('/masks/')) {
            masks.push(file);
        }
    }
    if (images.length === 0 || masks.length === 0) {
        return true;
    }
    return false;

}

// Case IV - images/ and masks/ folders have different number of images
const TestDifferentNumberOfImages = (files) => {
    let images = [];
    let masks = [];
    for (let file of files) {
        if (file.webkitRelativePath.includes('/images/')) {
            images.push(file);
        }
        if (file.webkitRelativePath.includes('/masks/')) {
            masks.push(file);
        }
    }
    if (images.length !== masks.length) {
        return true;
    }
    return false;
}

// Case V - images/ and masks/ folders have different names or extensions
const TestDifferentNamesExtensions = (files) => {
    let images = [];
    let masks = [];
    
    for (let file of files) {
        if (file.webkitRelativePath.includes('/images/')) {
            images.push(file);
        }
        if (file.webkitRelativePath.includes('/masks/')) {
            masks.push(file);
        }
    }
    for (let i = 0; i < images.length; i++) {
        const image = images[i];
        const mask = masks[i];
        if (image.name.split('.')[0] !== mask.name.split('.')[0]) {
            return true;
        }

    }
    return false;
} */

const SanitizeFilename = (filename) => {
    // Extract the file extension
    const extension = filename.slice(filename.lastIndexOf('.')) || '';
    // Extract the name without extension
    const name = filename.slice(0, filename.lastIndexOf('.')) || filename;
    // Replace any non-alphanumeric character in the name part only
    const sanitized = name.replace(/[^a-zA-Z0-9]/g, '_');
    // Return the sanitized name with the original extension
    return sanitized + extension;
}

export const NoAccessDisabledUploadButton = () => {
    return (
        <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-not-allowed bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                </svg>
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">No access!</span> Your organization is not yet permitted to use the Advex platform.</p>
                <p className="text-xs text-gray-500 dark:text-gray-400">Contact support@advexai.com for more information.</p>
            </div>
        </label>
    );
}


export const TextualGuidance = ({ objectVariations, setObjectVariations }) => {
    const [errorMessage, setErrorMessage] = useState('');

    const handleVariationChange = (index, field, value) => {
        const newVariations = [...objectVariations];
        newVariations[index][field] = value;
        setObjectVariations(newVariations);
    };

    const addVariation = () => {
        // Validation for new variation addition
        if (objectVariations.some(variation => !variation.description || variation.images.length < 5)) {
            // Case I: both description and images missing
            if (objectVariations.some(variation => !variation.description && variation.images.length < 5)) {
                setErrorMessage('Each variation must have both a description and at least 5 reference images.');
                return;
            }

            // Case II: description missing
            if (objectVariations.some(variation => !variation.description)) {
                setErrorMessage('Each variation must have a description.');
                return;
            }
            // Case III: not enough images
            if (objectVariations.some(variation => variation.images.length < 5)) {
                let lastImagesUploadedCounter = objectVariations[objectVariations.length - 1].images.length;
                setErrorMessage(`Each variation must have at least 5 images. Images uploaded: ${lastImagesUploadedCounter}`);
                return;
            }

            // Case IV: General error
            setErrorMessage('Each existing variation must have at least 5 images and a description. Contact support for more information.');
            return;
        }

        setErrorMessage(''); // Clear the error message if validation passes
        setObjectVariations([...objectVariations, { description: '', images: [] }]);
    };

    const removeVariation = (index) => {
        const newVariations = objectVariations.filter((_, i) => i !== index);
        setObjectVariations(newVariations);
    };

    return (
        <div className="p-4 border rounded-lg bg-gray-100">
            <h2 className="text-xl text-center font-bold mb-4">Generate Specific Variations</h2>

            {/* Guidelines on what to upload */}
            <div className="flex items-center mt-5 mb-10">
                <div className="flex-1 p-2 border text-gray">Summarize the contents of your images in as few words as possible</div>
                <div className="flex-1 border p-2 text-gray">Upload at least 5 reference images containing the subject of interest</div>
            </div>

            {objectVariations.map((variation, index) => (
                <div key={index} className="flex items-center mb-2">
                    <input
                        type="text"
                        placeholder="An image of a crushed box"
                        required
                        value={variation.description}
                        onChange={(e) => handleVariationChange(index, 'description', e.target.value)}
                        className="border p-2 rounded mr-4 flex-1 bg-white text-black placeholder-gray-500"
                    />
                    <input
                        type="file"
                        multiple
                        onChange={(e) => handleVariationChange(index, 'images', Array.from(e.target.files))}
                        className="border p-2 rounded mr-2 bg-white text-black placeholder-gray-500"
                    />
                    <button
                        type="button"
                        onClick={() => removeVariation(index)}
                        className="text-red-600 hover:text-red-800"
                    >
                        &times;
                    </button>
                </div>
            ))}

            {/* Error message */}
            {errorMessage && <p className="text-red-600 mb-2">{errorMessage}</p>}


            <button
                type="button"
                onClick={addVariation}
                className="mt-4 text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-4 py-2"
            >
                Add another variation
            </button>
        </div>
    );
};


export const UploadPage = () => {
    const user = useUser();

    const [files, setFiles] = useState([]);
    const [uploadTriggered, setUploadTriggered] = useState(false);
    const [uploading, setUploading] = useState(false);

    // Textual Guidance
    const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
    const [objectVariations, setObjectVariations] = useState([{description: '', images: []}]);

    // Dataset Description
    const [datasetDescription, setDatasetDescription] = useState('');

    // Get user details
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [uid, setUid] = useState('');
    const [authToken, setAuthToken] = useState('');

    const navigate = useNavigate();
    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        const originalFiles = Array.from(event.target.files);	

        // Use the original files array directly without modification
        setFiles(originalFiles);
        setUploadTriggered(true); // Indicate that an upload process should be initiated
    };

    useEffect(() => {
        if (user) {
            setName(user.displayName);
            setEmail(user.email);
            setUid(user.uid);
            user.getIdToken().then((token) => {
                setAuthToken(token);
            });
        }
    }
    , [user]);

    useEffect(() => {
        if (uploadTriggered && files.length > 0) {
            handleSubmit();
            setUploadTriggered(false); // Reset the trigger
        }
    }, [uploadTriggered, files]); // Depend on files and trigger state

    const resetInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = ""; // Clear the input field
        }
    };

    const folderStructure = useFolderStructure();

    const handleSubmit = async () => {
        if (uploading) {
            return; // Prevent multiple uploads
        }

        
        setUploading(true);
        
        // // Validation for submit
        // if (objectVariations.some(variation => !variation.description || variation.images.length < 5)) {
        //     alert('Each variation must have at least 5 images and a corresponding description.');
        //     setUploading(false);
        //     setFiles([]);
        //     resetInput(); // Reset the file input
        //     return;
        // }

        const formData = new FormData();
        files.forEach(file => {
            formData.append('files', file);
        });

        // get workflow type
        // const folderStructure = useFolderStructure();

        // add another field to the form data. user email and name
        formData.append('email', email);
        formData.append('name', name);
        formData.append('uid', uid);
        formData.append('workflow_type', folderStructure.type);

        //Add dataset descripotion to the form data
        console.log('Dataset Description:', datasetDescription); // Debug log
        formData.append('dataset_description', datasetDescription);

        // After appending to formData, add this to verify it was added correctly:
        console.log('FormData content:');
        for (let pair of formData.entries()) {
            console.log(pair[0], pair[1]);
        }


        // Add textual guidance to the form data
        if (objectVariations === null || objectVariations.length === 0 || objectVariations[0].images.length === 0) {
            const emptyFile = new Blob([], { type: 'application/octet-stream' });
            const emptyFileWithMeta = new File([emptyFile], 'empty.txt', { type: 'text/plain' });
            formData.append('textual_guidance_files', emptyFileWithMeta);
            formData.append('textual_guidance_descriptions', '');
        }
        else {
            objectVariations.forEach((variation, index) => {
                variation.images.forEach((imageFile) => {
                    formData.append('textual_guidance_files', imageFile);
                    formData.append('textual_guidance_descriptions', variation.description);
                });
            });
        }
        const generalValidations = [
            { test: TestFoldersNotPresent, message: "/images and /masks folders not present." },
            { test: TestFewerThanTenImages, message: "You must upload at least 10 images." },
            { test: TestFoldersEmpty, message: "/images or /masks folder empty." },
            { test: TestDifferentNumberOfImages, message: "All sets must contain the same number of images." },
            { test: TestDifferentNamesExtensions, message: "File names must be consistent across all files." },
            { test: TestFileLimitExceeded, message: "Max file limit is 400 files"}
        ];
    
        const translationValidations = [
            { test: TestTranslationFoldersStructure, message: "Missing required folders. Need: defective/images, defective/masks, and clean/images." },
            { test: TestTranslationFoldersEmpty, message: "One or more required folders are empty." },
            { test: TestTranslationDefectiveMinimumImages, message: "You must upload at least 10 images in both defective/images and defective/masks folders." },
            { test: TestTranslationDefectiveMatchingCount, message: "Number of images in defective/images must match number of masks in defective/masks." },
            { test: TestTranslationDefectiveMatchingNames, message: "File names and extensions must be identical between defective/images and defective/masks." },
            { test: TestFileLimitExceeded, message: "Max file limit is 400 files"}
            
        ];
    
        // Run validations based on workflow type
        const validations = folderStructure.type === 'general' ? generalValidations : translationValidations;
        
        for (let validation of validations) {
            if (validation.test(files)) {
                alert(`Validation failed: ${validation.message}`);
                setUploading(false);
                setFiles([]);
                resetInput();
                return;
            }
        }
        /* // Define validations with custom messages
        const validations = [
            { test: TestFoldersNotPresent, message: "/images and /masks folders not present." },
            { test: TestFewerThanTenImages, message: "You must upload at least 10 images." },
            { test: TestFoldersEmpty, message: "/images or /masks folder empty." },
            { test: TestDifferentNumberOfImages, message: "All sets must contain the same number of images." },
            { test: TestDifferentNamesExtensions, message: "File names must be consistent across all files." }
        ];

        // Run validations
        if(folderStructure.type == 'general')
        {
            for (let validation of validations) {
                if (validation.test(files)) {
                    alert(`Validation failed: ${validation.message}`);
                    setUploading(false);
                    setFiles([]);
                    resetInput(); // Reset the file input
                    return;
                }
            }
        } */

        
        try {
            
            const url = backend_url + '/upload_images' // prod mode
            // await print formData
            for (var pair of formData.entries()) {
                console.log(pair[0]+ ', ' + pair[1]);
            }

            const response = await fetch(url, {
                method: 'POST',
                body: formData,
                headers: {
                    'ngrok-skip-browser-warning': 'true',
                    'Authorization': `Bearer ${authToken}`,
                    // 'Content-Type': 'multipart/form-data',
                    // 'accept': 'application/json',
                }
            });
            
            if (response.ok) {
                const result = await response.json();
                console.log('Upload successful', result);
                let id = result.run_id;
                // Redirect to success page programmatically
                navigate('/metrics/' + id);
            } else {
                throw new Error('Upload failed');
            }
        } catch (error) {
            console.error('Error:', error);
            // alert error to user (show full error)
            navigate('/error');
        } finally {
            setUploading(false); // Set uploading to false when upload completes
            resetInput(); // Reset the file input
        }
    };

    const guidelines_url = folderStructure.type === 'translation'
                    ? 'https://docs.google.com/document/d/1giBFwziFHfs6_EHv_pdLViI4lkjYvEk2AjmYpEc8yf4/edit?usp=sharing'
                    : 'https://docs.google.com/document/d/1B703KCQBQNWoQANkOEmpKq-VY86MMjOV7QsjH8ExbK4/edit?usp=sharing';

    return (
        <>
        <div className="min-h-screen flex flex-col">
            <div className="flex-grow">
                {/* Title */}
                <div className="App">
                    <div className="mb-8 mt-8">
                        <h1 className="text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl">Advex Platform</h1>
                        <h1 className="text-2xl leading-none tracking-tight text-gray-900 md:text-3xl lg:text-3xl">Solve Data. Solve AI.</h1>
                        {/* if it's a translation workflow, show this. otherwise, show "XXDX" */}
                        {folderStructure.type === 'translation' && (
                            <h2 className="text-2xl mt-4 text-gray-600">
                                Translate objects (defects) onto other objects (non-defective parts)
                            </h2>
                        )}
                        {folderStructure.type !== 'translation' && (
                            <h2 className="text-2xl mt-4 text-gray-600">
                                Create diverse, labeled images from a small set of existing images
                            </h2>
                        )}


                    </div> 
                </div>
        
                {/* Guidelines */}

                <div>
                    <br></br>
                    <p className="text-xl text-center mt-4">Not sure where to start? Learn more about our <a target="_blank" href={guidelines_url} className="text-blue-600">guidelines</a>.</p>
                </div>

                {/* Upload functinality */}


                {/* Check if the user is authorized to upload */}
                
                
                <div className="flex items-center justify-center w-full">
                    <div className="w-2/5"> {/* Container covering 40% of the area */}
                        {!approved_organizations.includes(email.split('@')[1]) && <NoAccessDisabledUploadButton />}

                        {approved_organizations.includes(email.split('@')[1]) && (
                            <>
                        {uploading ? ( // Show spinner if uploading is in progress
                            <div className="relative w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 flex flex-col items-center justify-center">
                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white opacity-20">Uploading files. This may take a few minutes...</h5>
                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload folders</span></p>
                                <p className="text-xs text-gray-500 dark:text-gray-400">{folderStructure.description}</p>
                                <div role="status" className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                    <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                    </svg>
                                    <span className="sr-only">loading...</span>
                                </div>
                            </div>
                        ) : (
                            <>
                            <div className="border rounded-lg p-2 bg-white mb-5 mt-5">
                                {/* <button
                                    type="button"
                                    // className="w-full text-left px-4 py-2 border-b border-gray-200 flex justify-between items-center"
                                    className={`w-full text-left px-4 py-2 border-b border-gray-200 flex justify-between items-center cursor-not-allowed hover:bg-gray-100 text-gray-400`}
                                    disabled={true} // Set this to true to disable the button
                                    onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}
                                >
                                    <span className="text-lg font-medium">🚧 Advanced Settings (Coming Soon!)</span>
                                    <span>{showAdvancedSettings ? '-' : '+'}</span>
                                </button>
                                {showAdvancedSettings && (
                                    <TextualGuidance
                                        objectVariations={objectVariations}
                                        setObjectVariations={setObjectVariations}
                                    />
                                )} */
                                
                                <div className="w-full">
                                    <input
                                        type="text"
                                        placeholder="Step 1: Describe your dataset"
                                        value={datasetDescription}
                                        onChange={(e) => setDatasetDescription(e.target.value)}
                                        className="w-full px-4 py-2 text-lg border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white text-gray-900 placeholder-gray-400"
                                    />
                                    <div className="mt-2 px-4 py-2 text-base text-gray-500">
                                        <span className="font-medium italic">Example:</span> These are pictures of defective washers with dent and ding defects. The defects can be in any part of the washer and they are multicolored. There are typically 1 to 3 defects per part.
                                    </div>
                                </div>
                                
                                }
                            </div>

                    
                            
                            <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                                    </svg>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload folders</span></p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">{folderStructure.description}</p>
                                </div>
                                <input id="dropzone-file" type="file" className="hidden" multiple webkitdirectory="true" onChange={handleFileChange}/>
                            </label>
                            </>
                        )}
                        </>
                        )}
                    </div>
                </div>

                {/* Examples */}
                <div>
                    <div class="relative flex mt-10 items-center">
                        <div class="flex-grow"></div>
                        <div class="flex-shrink w-2/5">
                            <div class="border-t border-gray-400"></div>
                        </div>
                        <span class="mx-4 text-black-400 text-2xl">Try out examples</span>
                        <div class="flex-shrink w-2/5">
                            <div class="border-t border-gray-400"></div>
                        </div>
                        <div class="flex-grow"></div>
                    </div>

<div class="grid mt-10 justify-center gap-4 grid-cols-1 md:grid-cols-3">
                        <a href="/metrics/81cdc9aed13b4b91907d99368dce7b6f" class="block w-full max-w-lg mx-auto">
                            <figure class="max-w-full">
                                <img class="h-auto max-w-full rounded-lg" src={process.env.PUBLIC_URL + '/PackageLoopFinal.gif'} alt="package segmentation model report"/>
                                <figcaption class="mt-2 text-big font-extrabold text-center text-black-500 dark:text-black-400">Package Segmentation</figcaption>
                            </figure>
                        </a>
                        <a href="/metrics/1b6dfe44f5184c828b42a44824562b55" class="block w-full max-w-lg mx-auto">
                            <figure class="max-w-full mx-auto">
                                <img class="h-auto max-w-full rounded-lg" src={process.env.PUBLIC_URL + '/RoadLoop.gif'} alt="plant segmentation model report"></img>
                                <figcaption class="mt-2 text-big text-center font-extrabold text-black-500 dark:text-black-400">Road Segmentation</figcaption>
                            </figure>
                        </a>
                        <a href="/metrics/4819869023bd43fe9781860384a97220" class="block w-full max-w-lg mx-auto">
                            <figure class="max-w-full mx-auto">
                                <img class="h-auto max-w-full rounded-lg" src={process.env.PUBLIC_URL + '/BoxesLoop.gif'} alt="bin picking model report"></img>
                                <figcaption class="mt-2 text-big text-center font-extrabold text-black-500 dark:text-black-400">Box Unloading</figcaption>
                            </figure>
                        </a>

                        <a href="/metrics/4819869023bd43fe9781860384a97221" class="block w-full max-w-lg mx-auto">
                            <figure class="max-w-full mx-auto">
                                <img class="h-auto max-w-full rounded-lg" src={process.env.PUBLIC_URL + '/small_defects.gif'} alt="defect detection model report"></img>
                                <figcaption class="mt-2 text-big text-center font-extrabold text-black-500 dark:text-black-400">PCB Defects</figcaption>
                            </figure>
                        </a>

                        <a href="/metrics/7594bd7d-97f3-4077-a106-a5eb8ee6bc15" class="block w-full max-w-lg mx-auto">
                            <figure class="max-w-full mx-auto">
                                <img class="h-auto max-w-full rounded-lg" src={process.env.PUBLIC_URL + '/small_mvtec.gif'} alt="defect detection mvtec model report"></img>
                                <figcaption class="mt-2 text-big text-center font-extrabold text-black-500 dark:text-black-400">Carpet Defects</figcaption>
                            </figure>
                        </a>

                        <a href="/metrics/4819869023bd43fe9781860384a97222" class="block w-full max-w-lg mx-auto">
                            <figure class="max-w-full mx-auto">
                                <img class="h-auto max-w-full rounded-lg" src={process.env.PUBLIC_URL + '/small_aigen.gif'} alt="weed detection model report"></img>
                                <figcaption class="mt-2 text-big text-center font-extrabold text-black-500 dark:text-black-400">Weed Detection</figcaption>
                            </figure>
                        </a>

                   </div>

                </div>

                {/* Footer */}
                <br></br>
                <br></br>
                <br></br>
                <Footer />
            </div>
        </div>
        </>
    );
};

// List of constants used in the application
// ----------------------------------------------------------------
export const backend_url = 'https://advex-client-backend.ngrok.app';
export const example_routes = [
    "4819869023bd43fe9781860384a97224", 
    "4819869023bd43fe9781860384a97223", 
    "4819869023bd43fe9781860384a97222", 
    "81cdc9aed13b4b91907d99368dce7b6f", 
    "1b6dfe44f5184c828b42a44824562b55", 
    "4819869023bd43fe9781860384a97220", 
    "7594bd7d-97f3-4077-a106-a5eb8ee6bc15", 
    "fd6c3428f7a147069d4787191ff0ef55", 
    "4819869023bd43fe9781860384a97221",
    "0aaa9411cd144cd1be28c5cc52cddaf4", // MG delivery
    "67e6c181e1c44ac2ab8046475c4903fe", // SE delivery
    "f777393d879f4857bafd13eabfd7922c-general", // Example segmentation WF
    "b22696c454884f29a488b2e50e23e99d-translation", // Example translation WF
    "00000000-0000-0000-0000-000000000000", // Debug run
    "f777393d879f4857bafd13eabfd7922c-general", // Debug old run
    "a87378292ae54017b13ecd7d49351257-translation", // BSCI - temp core stock
    "b3b5f404b5864f48add3f5322e887c9c-translation", // BSCI - temp pe-fiber
    "d8d54697d15842b88f6e6bf38bb0945a-translation", // BSCI - temp cut and tare
    "c9979019bc634d71aa411009a4cf340f-translation", // BSCI - temp embedded particles
    "03d6eb34252f4352ae1c52f70e776b9e-general" // VC for customer display

];
export const approved_organizations = ["advexai.com", "simplevisiontechnology.com", "roboflow.com", "bsci.com", "gmail.com"];
